export default {
  heading: 'Comfortaa, sans-serif',
  subheading: 'Wire One',
  body: 'Comfortaa, sans-serif',
  body2: 'Futura, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  menuTitle: 'Source Code Pro',
  menuSubtitle: 'Avenir Next',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa:100,200,300,400,500,600,700,800,900',
    'Barlow:100,200,300,400,500,600,700,800,900',
    'Wire One',
    'Source Code Pro:200,300,400,500,600,700,800,900'
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura', 'Avenir Next'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css',
    'https://www.gonation.biz/fonts/avenirnext/avenirnext.css'
  ]
}
