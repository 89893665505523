const variantName = 'menuV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      justifyContent: 'center',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: ['0rem 1rem 2rem']
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    backToMenuBtn: {
      variant: 'buttons.primary',
      backgroundColor: 'primary',
      margin: '1rem 1rem 2rem',
      cursor: 'pointer',
      justifyContent: 'center',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '50%' // * reuseable to make images square
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      marginBottom: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px'
      }
    },

    sectionTab: {
      margin: '0.25rem',
      minWidth: 'max-content',
      maxWidth: 'max-content',
      width: '100%',
      border: '1px solid black',
      padding: '0.75rem 1rem 1rem',
      cursor: 'pointer',
      backgroundColor: 'secondary',
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: '1.5rem',
      color: 'light',
      '&:hover': {
        backgroundColor: 'black',
        color: '#fff',
        transition: 'all 0.5s'
      }
    },

    sectionTabActive: {
      variant: `${variantName}.sectionTab`,
      backgroundColor: 'primary',
      color: 'light'
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================

    allInContainerWrapper: {
      variant: 'customVariants.borderCorners',
      position: 'relative',
      maxWidth: '950px',
      margin: '3rem auto',
      '#sec-czWHcexTTc2w3NkPUHfd3w': {
        '.menuItemContainerImgActive': {
          width: '100%'
        }
      },
      '.menu': {
        '> div:nth-of-type(odd)': {
          border: 'solid 3px',
          borderColor: 'primary',
          borderRadius: '25px',
          '.menuSectionTitle': {
            p: '1rem'
          }
        },
        '> div:nth-of-type(even)': {
          border: ['solid 15px', '', 'solid 27px'],
          borderColor: ['rgb(25,97,173)', 'rgb(25,97,173)', 'rgb(25,97,173)', 'rgb(25,97,173)'],
          borderRadius: '25px',
          overflow: 'hidden',
          backgroundColor: 'rgb(25,97,173)',
          '.menuSectionTitle': {
            backgroundColor: 'rgb(25,97,173)',
            p: '0.5rem 1rem 1rem',
            color: 'white'
          }
        },
        '> div.menuContainer': {
          display: 'none',
          border: 'none !important',
          color: 'text',
          '.menuSectionTitle': {
            color: 'text'
          }
        },
        '#sec-gHHWhh5wRBOIwWLNTjKmfA, #sec-jW6aa-cFSK6MaqEwiniJEw, #sec-FjjIrDw4S0aINq1Y1qGX-g, #sec-Xt7hkIswS6WYxGVAVFvQZA':
          {
            '.allInContainer .menuSectionTitle': {
              fontSize: '1.5rem'
            },

            '.menuSectionDescription': {
              pb: '0rem',
              fontWeight: '300',
              paddingTop: '2rem',
              fontSize: '1.15rem'
            }
          },

        '#sec-Xt7hkIswS6WYxGVAVFvQZA': {
          '.menuSectionDescription': {
            pb: '2rem'
          }
        },

        '#sec-Xt7hkIswS6WYxGVAVFvQZA > .menuContainer .menuItemsContainer': {
          '> div:last-child': {
            width: ['100%', '100%', '100%', '100%', '100%'],
            mt: '1.5rem',
            '.menuItemName': {
              fontSize: '1.75rem'
            },
            '.menuItemNamePriceContainer, .menuItemDescription': {
              textAlign: 'center',
              width: '100%'
            }
          }
        }
      },

      '#sec-gHHWhh5wRBOIwWLNTjKmfA > .menuContainer > .menuItemsContainer': {
        display: 'none'
      },

      '#sec-xLgD9q-PRRSk1YfwO9hOaQ, #sec-2m8TSPOXSGCd48s3mtT_rg': {
        '.menuSectionDescription': {
          fontSize: '1.25rem',
          mb: '0.5rem'
        }
      },

      '#sec-2m8TSPOXSGCd48s3mtT_rg': {
        '.menuItemsContainer': {
          p: ['0rem 1rem', '', '0.5rem 0rem 0rem']
        }
      },

      '#sec-FjjIrDw4S0aINq1Y1qGX-g, #sec-jW6aa-cFSK6MaqEwiniJEw': {
        '.menuItemsContainer': {
          pt: '3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        },
        '.menuItemNamePriceContainer': {
          textAlign: 'center',
          '.menuItemName': {
            fontSize: '1.5rem',
            textTransform: 'uppercase'
          }
        }
      }
    },
    menuContainer: {
      p: '0rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '> div': {
        width: '100%'
      }
    },
    allInContainer: {
      mb: '2rem'
    },

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: '100%'
    },

    sectionCell: {
      cursor: 'pointer',
      width: ['100%', 'calc(50% - (0.5rem * 2))'],
      backgroundColor: 'secondary',
      margin: ['1rem 0', '0.5rem'],
      overflow: 'hidden',
      ':hover .cellImageContainer ': {
        transform: 'scale(1.2)'
      }
    },

    menuCell: {
      position: 'relative',
      flexWrap: 'wrap',
      height: '100%',
      backgroundColor: 'background'
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset',
      transition: 'all ease-in-out 0.6s',
      filter: 'brightness(0.9)'
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellImageLogo: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    cellName: {
      fontSize: ['1.75rem', '3rem', '4rem', '4.5rem'],
      fontWeight: 'normal',
      textTransform: 'uppercase',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      transform: 'translate(-50%, -50%)',
      fontFamily: 'display',
      textAlign: 'center'
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      color: 'dark',
      padding: '0.5rem',
      fontFamily: 'menuTitle',
      fontWeight: '300',
      textTransform: 'uppercase',
      fontSize: ['1.75rem', '1.75rem', '2rem', '2.5rem', '2.75rem'],
      textAlign: 'center',
      mb: '0rem',
      letterSpacing: '-1px',
      color: 'text',
      width: '100%'
    },

    menuSectionDescription: {
      fontSize: '0.9rem',
      textAlign: 'center',
      padding: ['1.5rem', '', '', '1rem 2rem'],
      fontFamily: 'menuTitle',
      letterSpacing: '-1px',
      m: 'auto',
      lineHeight: '1.6',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: '30px 30px 0px 0px',
      marginBottom: '-26px',
      pb: ['36px', '36px', '36px', '36px'],
      zIndex: '2',
      whiteSpace: 'break-spaces'
    },

    menuItemsContainer: {
      p: '2rem 1rem',
      borderRadius: '30px',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      backgroundColor: 'white'
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      padding: ['0rem', '', '0rem 1rem 0rem 1rem'],
      width: ['100%', '', 'calc(50% - (0.5rem * 2))'],
      margin: '0rem 0rem 0.5rem'
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`
    },
    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
      '.menuItemImgDefaultContainer': {
        display: 'none'
      }
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['30%', '150px'],
      maxHeight: ['150px'],
      padding: '0 0.75rem 0 0'
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: ['130px', '150px', '175px'],
      cursor: 'pointer',
      borderRadius: '10px'
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none'
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    menuItemContentContainer: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      // flexBasis: 2,
      width: '100%',
      // margin: '1rem 0rem',
      position: 'relative'
    },
    menuItemName: {
      fontSize: ['1.1rem', '1.25rem', '', '1.35rem'],
      paddingRight: '8px',
      margin: '0rem',
      // textTransform: 'uppercase',
      fontFamily: 'menuTitle',
      // letterSpacing: '1px',
      color: 'black',
      textAlign: 'left'
    },
    menuItemDescription: {
      fontSize: '0.85rem',
      whiteSpace: 'break-spaces',
      width: '90%',
      fontSize: '1rem',
      fontWeight: '300',
      color: 'black',
      letterSpacing: '-1px',
      fontFamily: 'menuTitle'
    },

    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end'
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
      fontSize: ['1rem', '1rem', '1rem'],
      textTransform: 'uppercase',
      fontFamily: 'menuTitle',
      letterSpacing: '2px',
      color: 'primary'
    },
    itemVariantsContainer: {
      my: '1rem'
    },

    variantsContainer: {},

    labelTitle: {
      display: 'flex'
    },
    variantContainer: {
      marginBottom: '8px',
      display: 'flex'
    },

    variantContainerFloatTopRight: {
      position: 'absolute',
      right: '0rem',
      top: '0rem'
    },

    menuItemPriceLabel: {
      margin: '0px',
      marginRight: '8px',
      fontFamily: 'menuTitle',
      fontSize: ['1rem', '1rem', '1rem']
    },
    menuItemPriceVariants: {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: '300',
      fontSize: ['1rem', '1rem', '1.1rem'],
      fontFamily: 'menuTitle'
    },

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
      marginBottom: '1rem',
      width: '100%',
      display: 'none!important'
    },

    menuDropdownBtn: {
      variant: 'buttons.primary'
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      opacity: '0.8',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center'
      }
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline'
        }
      }
    }
  }
}
