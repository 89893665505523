export default {
  text: '#555555',
  text2: '#555555',
  primary: '#4d8ecd',
  secondary: '#5c7b9a',
  background: '#ffffff',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#ffffff',
  backgroundTertiary: '#ffffff',
  light: '#FFF',
  dark: '#555555'
}
